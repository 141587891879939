















import { SGM } from "@/api"
import { FormHelper, Toast } from "@/mixins"
import { Component, Mixins } from "vue-property-decorator"

@Component
export default class TransferCharacterModal extends Mixins(FormHelper, Toast) {
  isOpen = false
  isCreate = false
  gameMasterAccountId = ''
  playerAccountId = ''
  accountName = ''

  async mounted() {
    this.$root.$on('openTransferBackCharacterModal', (data) => {
      this.isOpen = true
      this.isCreate = true
      this.gameMasterAccountId = data.gameMasterAccountId
      this.playerAccountId = data.playerAccountId
      this.accountName = data.accountName
    })
  }

  async submit() {
    try {
      await SGM.transferCharacterBack({
        gameMasterAccountId: this.gameMasterAccountId,
        playerAccountId: this.playerAccountId
      })

      this.showSuccessToast({
        title: 'Successfully transferred back!',
        message: `Accounts from ${this.accountName} has been transferred back successfully`
      })
    } catch(e) {
      console.error(e)
    }

    this.$emit('refreshCharacterTransferTable')
  }
}
